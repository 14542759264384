import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import theme from "./theme"
import Viewport from "./viewport"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Viewport />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {element}
      </ThemeProvider>
    </>
  )
  //  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
